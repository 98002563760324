import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

import Dashboard from './components/Dashboard/Dashboard';

import PlaceOrder from './components/Order/PlaceOrder';
import OrderHistory from './components/Order/OrderHistory';
import OrderDetails from './components/Order/OrderDetails'

import AccountDetails from './components/Account/AccountDetails';
import AuthorizedUsers from './components/Account/AuthorizedUsers';
import PaymentMethod from './components/Account/PaymentMethod';


import AboutMe from './components/User/AboutMe';

import Support from './components/Misc/Support';

import Login from './components/Pages/Login';
import Register from './components/Pages/Register';

//import NotFound from './components/Pages/NotFound';

import AppliedRoute from "./components/Common/AppliedRoute";



// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const staticPages = [
    '/register'
];

const Routes = ({ location,childProps}) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInUp'
    //      'rag-fadeInDown'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    //      'rag-fadeInUpBig'
    //      'rag-fadeInDownBig'
    //      'rag-fadeInRightBig'
    //      'rag-fadeInLeftBig'
    //      'rag-zoomBackDown'
    const animationName = 'rag-fadeIn';

    if(staticPages.indexOf(location.pathname) > -1 || location.pathname.startsWith("/login")) { // login needs the startswith match due to reset URL hash
        if(childProps.isAuthenticated && (location.pathname==="/login" || location.pathname==="/register")) {
            return (
                <Redirect to='/dashboard' />
            )
        } else {
            return (
                    // Page Layout component wrapper
                <BasePage>
                    <Switch location={location}>
                        <AppliedRoute path="/login/admin/:auth_code/:account_id" component={Login} props={childProps}/>
                        <AppliedRoute path="/login/oc/:one_click" component={Login} props={childProps}/>
                        <AppliedRoute path="/login/:hash" component={Login} props={childProps}/>
                        <AppliedRoute path="/login" component={Login} props={childProps} />
                        <AppliedRoute path="/register" component={Register} props={childProps} />
                    </Switch>
                </BasePage>
            )
        }
    }
    else {
        if(childProps.isAuthenticated===false) {
            return (
                <Redirect to='/login' />
            )
        } else {
            return (
                // Layout component wrapper
                // Use <BaseHorizontal> to change layout
                <Base {...childProps}>
                  <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Switch location={location}>
                                <AppliedRoute path="/dashboard" component={Dashboard} props={childProps}/>
                                <AppliedRoute path="/place-order" component={PlaceOrder} props={childProps}/>
                                <AppliedRoute path="/orders" component={OrderHistory} props={childProps}/>
                                <AppliedRoute path="/order/:order_id" component={OrderDetails} props={childProps}/>

                                <AppliedRoute path="/payment-method" component={PaymentMethod} props={childProps}/>
                                <AppliedRoute path="/account" component={AccountDetails} props={childProps}/>
                                <AppliedRoute path="/users" component={AuthorizedUsers} props={childProps}/>

                                <AppliedRoute path="/aboutme/:action" component={AboutMe}/>
                                <AppliedRoute path="/aboutme" component={AboutMe}/>


                                <AppliedRoute path="/support" component={Support}/>

                                <Redirect to="/login"/>
                            </Switch>
                        </div>
                    </CSSTransition>
                  </TransitionGroup>
                </Base>
            )
        }
    }
}

export default withRouter(Routes);
