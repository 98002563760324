import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Row, Col, Button, Card, CardBody, Input} from 'reactstrap';
import MaskedInput from 'react-maskedinput'

class AccountDetails extends React.Component {

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Account Details</div>
                </div>
                <div className="text-center">
                    To change any locked account details, please contact us
                </div>
                <Card className="card-default">
                    <CardBody>
                        <form className="form-horizontal" onSubmit={this.onSubmit}>
                            <Row>
                                <label className="col-xl-3 col-form-label">Storyteller Account #</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="344334" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Government License #</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="LC838234434" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Account Name</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="York Street Keg" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Address</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="3453 York Street" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Unit #</label>
                                <div className="col-xl-9">
                                    <Input type="text" placeholder="Optional" value=""/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">City</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="Toronto" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Province</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="ON" disabled/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Postal Code</label>
                                <div className="col-xl-9">
                                    <Input type="text" value="A1B2C4" disabled/>
</div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Phone #</label>
                                <div className="col-xl-9">
                                    <MaskedInput type="tel" className="form-control" mask="(111) 111-1111" name="card" size="10" onChange={this.onChangeMasked} value="4161231234"/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Website</label>
                                <div className="col-xl-9">
                                    <Input type="text"  value="http://www.mywebsite.ca"/>
                                </div>
    `                       </Row>
                            <Row>
                                <label className="col-xl-3 col-form-label">Delivery Instructions</label>
                                <div className="col-xl-9">
                                    <Input type="textarea" placeholder="e.g. Deliver to back door" />
                                </div>
    `                       </Row>
                            <Row>
                                <div className="offset-xl-3 col-xl-9">
                                    <button className="btn btn-primary" type="submit">Save Changes</button>
                                </div>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default (AccountDetails);
