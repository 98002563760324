import axios from 'axios';
import { API_ROOT } from '../../api-config';

axios.defaults.withCredentials = true;

export function getProductsParent(response) {
    axios.get(`${API_ROOT}/products/parent/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getProducts(response) {
    axios.get(`${API_ROOT}/products`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getFactors(response) {
    axios.get(`${API_ROOT}/factors/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getBrands(response) {
    axios.get(`${API_ROOT}/brands/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getCategories(response) {
    axios.get(`${API_ROOT}/categories/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getOwners(response) {
    axios.get(`${API_ROOT}/owners/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getTypes(response) {
    axios.get(`${API_ROOT}/types/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}